<template lang="pug">
.main-wrapper.estornos-listar
        header.main-wrapper-header
            .p-grid.p-align-center
                .p-col-12.ta-right
                    h1.text-header.text-secondary MedClub / <b>Estornos</b>

        Panel.my-2(header='Filtros' :toggleable='true' :collapsed='windowInnerWidth < 576' mode="indeterminate")
            .p-grid.p-fluid.p-align-end

                .p-col-12.p-md-4
                    label.form-label Cód. da transação:
                    .p-inputgroup
                        InputText(@keyup.enter.native='applyFilters()' v-model='filters.cd_transacao' @input='val => val || applyFilters()')
                        Button(icon='jam jam-search' @click='applyFilters()')

                .p-col-12.p-md-4
                    label.form-label Cód. da guia:
                    .p-inputgroup
                        InputText(@keyup.enter.native='applyFilters()' v-model='filters.cd_guia' @input='val => val || applyFilters()')
                        Button(icon='jam jam-search' @click='applyFilters()')

                .p-col-12.p-md-4
                    label.form-label CPF (pagante):
                    .p-inputgroup
                        InputMask(
                            mask='999.999.999-99'
                            placeholder='CPF'
                            :autoClear='false'
                            v-model='filters.nr_cpf'
                            @keydown.enter="applyFilters()"
                        )
                        Button(icon='jam jam-search' @click='applyFilters()')

                .p-col-12.p-md-2
                    label.form-label Tipo:
                    Dropdown(v-model='filters.ie_tipo' :options='options.estorno_tipos'
                        dataKey='value' optionLabel='text' optionValue='value' placeholder='- TODAS -' @change='applyFilters()' )

                .p-col-12.p-md-4
                    label.form-label Caixa supervisão:
                    .p-inputgroup
                        MultiSelect(
                            v-model='filters.cd_supervisao_list'
                            :options='options.caixas_supervisao'
                            optionLabel='text'
                            optionValue='value'
                            placeholder='- TODAS -'
                            @hide='applyFilters()'
                        )

                .p-col-12.p-md-2
                    label.form-label Situação:
                    Dropdown(v-model='filters.ie_situacao' :options='options.ie_situacao'
                        dataKey='value' optionLabel='text' optionValue='value' placeholder='TODAS' @change='applyFilters()' )

                .p-col-12.p-md-2
                    label.form-label Autorização:
                    Dropdown(v-model='filters.ie_situacao' :options='options.ie_tipo_operacao'
                        dataKey='value' optionLabel='text' optionValue='value' placeholder='TODAS' @change='applyFilters()' )
                        
                .p-col-12.p-md-2
                    label.form-label Formas de Pagamento:
                    .p-inputgroup
                        MultiSelect(
                            v-model='filters.cd_forma_pagamento_list'
                            :options='options.formas_pagamento'
                            optionLabel='text'
                            optionValue='value'
                            placeholder='- TODAS -'
                            @hide='applyFilters()'
                        )

                .p-col-12.p-md-2
                    label.form-label Data Inicial:
                    .p-inputgroup
                        Calendar( v-model='filters.dt_inicial' dateFormat="dd/mm/yy" :locale="ptbr"
                            :manualInput='false' :touchUI='windowInnerWidth < 576' placeholder='dd/mm/aaaa' @input='applyFilters()' )
                        Button.p-button-danger(icon='jam jam-rubber' v-tooltip.top="'Limpar'" @click='filters.dt_inicial = null; applyFilters()')

                .p-col-12.p-md-2
                    label.form-label Data Final:
                    .p-inputgroup
                        Calendar( v-model='filters.dt_final' dateFormat="dd/mm/yy" :locale="ptbr"
                            :manualInput='false' :touchUI='windowInnerWidth < 576' placeholder='dd/mm/aaaa' @input='applyFilters()' )
                        Button.p-button-danger(icon='jam jam-rubber' v-tooltip.top="'Limpar'" @click='filters.dt_final = null; applyFilters()')

                .p-col-12.p-md-2
                    label.form-label Data de Aprovação Inicial:
                    .p-inputgroup
                        Calendar( v-model='filters.dt_pagamento_inicial' dateFormat="dd/mm/yy" :locale="ptbr"
                            :manualInput='false' :touchUI='windowInnerWidth < 576' placeholder='dd/mm/aaaa' @input='applyFilters()' )
                        Button.p-button-danger(icon='jam jam-rubber' v-tooltip.top="'Limpar'" @click='filters.dt_pagamento_inicial = null; applyFilters()')

                .p-col-12.p-md-2
                    label.form-label Data de Aprovação Final:
                    .p-inputgroup
                        Calendar( v-model='filters.dt_pagamento_final' dateFormat="dd/mm/yy" :locale="ptbr"
                            :manualInput='false' :touchUI='windowInnerWidth < 576' placeholder='dd/mm/aaaa' @input='applyFilters()' )
                        Button.p-button-danger(icon='jam jam-rubber' v-tooltip.top="'Limpar'" @click='filters.dt_pagamento_final = null; applyFilters()')
                        
                .p-col-12.p-md-4
                    Button(label='Limpar filtros' icon='jam jam-rubber' @click='clearFilters()')

        ProgressBar(v-if='waiting' mode="indeterminate")
        div(v-else-if='! list.length')
            p.ta-center.text-secondary(style='margin-top: 40px;') Nenhum registro encontrado.
        div(v-else)
            DataView.dataview.my-2(:value="list" layout="grid")
                template(#grid="props")
                    .p-col-12.p-md-3(style="padding: .5em" :class="{ disabled: !props.data.ie_status }")
                        Panel.panel-list.ta-center(:header="`Estorno #${props.data.id}`" style='position: relative')
                            .ta-left
                                p <b>Cod. Transação: </b> {{ props.data.cd_transacao?.id }}
                                p <b>Motivo: </b> {{ props.data.cd_motivo_estorno?.ds_motivo }}
                                p <b>Situação: </b>
                                    span(v-if="props.data.ie_situacao === 'APR'" class="cell apr") APROVADO
                                    span(v-if="props.data.ie_situacao === 'CAN'" class="cell can") CANCELADO
                                    span(v-if="props.data.ie_situacao === 'PEN'" class="cell pen") PENDENTE
                                    span(v-if="props.data.ie_situacao === 'EDV'" class="cell edv") EM DEVOLUÇÃO

                                p <b>Data: </b> {{ props.data.dt_criado }}
                                p <b>Dt. Aprovação: </b> {{ props.data.dt_aprovado }}
                                p <b>Valor: </b> {{ formatPrice(props.data.nr_valor) }}

                                .ta-right.mt-2
                                    Button.p-button-raised(icon='pi pi-external-link' label='Ir para transação'
                                        @click='$router.push(`/transacoes-financeiras/visualizar/${props.data.cd_transacao}/`)')

            .p-grid.p-col-12.pr-0
                .p-col-4.mt-1
                    InputSwitch.mr-2(v-model='mostrarSelecionados' :disabled='!selecionados.length')
                    label.label-mostrar-selecionados Mostrar somente selecionados
                .p-col-5.ta-center.mt-1.pr-0
                        p.text-soma.ma-0(v-if='somaTotal') <b>Total Filtrado:</b> {{ formatPrice(somaTotal) }}
                        p.text-soma.ma-0.ml-2 <b>Selecionados:</b> {{ selecionados.length }}
                        p.text-soma.ma-0.ml-2 <b>Soma Selecionados:</b> {{ formatPrice(sum) }}
                        a.link-limpar.ml-2.mr-0.mt-1(v-if='sum > 0' @click='limparSelecao()') Limpar seleção
                .p-col-3.ta-right.mt-1.pr-0()
                    Button.mb-3(v-tooltip.top="'Imprimir'" :disabled='!verificaFiltrosImpressao()' icon='jam jam-printer' @click="imprimirRelatorio()")

            Panel.datatable(header='Lista de Estornos')
                Paginator.mb-1(:rows='paginadorDinamico.rows' :first='paginadorDinamico.first' :totalRecords='paginadorDinamico.total' @page="paginadorDinamico.onPage")
                DataTable.datatableEstornos(ref='datatableEstornos' :value="listaDinamica" dataKey="id" class="p-datatable-striped")
                    Column(headerStyle='width: 8%;' bodyStyle='text-align: center')
                        template(#header='{data}')
                            Checkbox(v-if='mostrarSelecionados ? selecionados.length : list.length' v-model='selecionarTodos' binary)
                        template(#body='props')
                            Checkbox(v-model='selecionadoIds' :value='props.data.id' @click.stop)
                    Column(headerStyle='width: 10%;' field='id' header='Cod.' bodyStyle='text-align:center;')
                        template(#body='props')
                            span {{ props.data.id }} <br>
                            .box-cell.cursor-default.mt-2(v-tooltip.top="statusTooltip(props.data.ie_tipo_operacao)" :class="{ \
                                atr: props.data.ie_tipo_operacao == 'ATR',\
                                nat: props.data.ie_tipo_operacao == 'NAT',\
                                pat: props.data.ie_tipo_operacao == 'PAT',\
                                }") {{ props.data.ie_tipo_operacao === 'ATR' ? 'AUT' : props.data.ie_tipo_operacao }}
                    Column(headerStyle='width: 15%;' field='cd_transacao' header='Cod. Transação' bodyStyle='text-align:center;')
                    Column(headerStyle='width: 50%;' field='ds_motivo' header='Motivo')
                        template(#body='props')
                            span.text-blue <b>{{ props.data.ds_motivo_estorno }}</b>
                            span.d-block.o-hidden(v-if='props.data.ds_motivo' style="font-size:12px") <b>{{ props.data.ds_motivo }}</b>
                            span.d-block.o-hidden(v-if='props.data.ds_observacao_pagamento' style="font-size:12px") {{ props.data.ds_observacao_pagamento }}
                    Column(headerStyle='width:15%;' field='nm_tipo' header='Tipo')
                    Column(headerStyle='width: 15%;' field='nm_usuario_cri' header='Usuário')
                    Column(headerStyle='width: 15%;' header='Situação' bodyStyle="padding: 0; height: 1px")
                        template(#body='props')
                            .cell(v-if="props.data.ie_situacao === 'APR'" :class="{apr: true}") <b> APROVADO </b>
                            .cell(v-if="props.data.ie_situacao === 'CAN'" :class="{can: true}") <b> CANCELADO </b>
                            .cell(v-if="props.data.ie_situacao === 'PEN'" :class="{pen: true}") <b> PENDENTE </b>
                            .cell(v-if="props.data.ie_situacao === 'EDV'" :class="{edv: true}") <b> EM DEVOLUÇÃO </b>
                    Column(headerStyle='width: 15%;' field='dt_criado_f' header='Data' bodyStyle='text-align:center;')
                    Column(headerStyle='width: 15%;' field='dt_aprovado_f' header='Dt. Aprovação' bodyStyle='text-align:center;')
                    Column(headerStyle='width: 15%;' header='Valor' bodyStyle='text-align:right;')
                        template(#body='props')
                            span {{ formatPrice(props.data.nr_valor) }}
                    Column(headerStyle='width: 12%;' bodyStyle='text-align: center;' header='Ações' headerClass='box-hide' bodyClass='box-hide')
                        template(#body='props')
                            Button.p-button-raised.p-button-rounded(v-tooltip.top="'Vizualizar transação'" icon='pi pi-external-link'
                                @click='$router.push(`/transacoes-financeiras/visualizar/${props.data.cd_transacao}/`)')
                Paginator.mb-1(:rows='paginadorDinamico.rows' :first='paginadorDinamico.first' :totalRecords='paginadorDinamico.total' @page="paginadorDinamico.onPage")

</template>

<style lang="scss">
    .estornos-listar {
        .forma_pag_span_price{
            font-size: 0.75rem;
        }
        .forma_pag_span_name{
            font-size: 0.75rem;
            font-weight: 700;
        }

        .dataview {
            @media all and (min-width: 577px) {
                display: none;
            }
        }
        .datatable {
            @media all and (max-width: 576px) {
                display: none;
            }
            .cell {
                padding: 16px 0;
                text-align: center;
                &.ex  {  background-color: #e4f8e1; }
                &.a  { background-color: #faf3dd; }
            }
            .p-progress-spinner {
                max-width: 23px;
                height: auto;
            }
            .text-blue { color: #007ad9 }
        }
        .cell {
            padding: 16px 0;
            text-align: center;
            height: 90%;
            display: flex;
            align-items: center;
            justify-content: center;

            &.atr { background-color: #c5e5ef; }
            &.nat { background-color: #fd7e75; }
            &.apr { background-color: #c5efcb; }
            &.can { background-color: #f8d6d9; }
            &.pen { background-color: #fff2cc; }
            &.edv { background-color: #fac490; }
        }
        .box-cell {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 36px;
            height: 36px;
            margin: 0 auto;
            text-align:center;
            font-weight:bold;
            color:#FFF;
            &.atr  { background-color: #0095e6; }
            &.pat  { background-color: #c09104; }
            &.nat  { background-color: #d61a1a; }
        }
    }
    .p-tooltip {
        max-width: none;
    }
    .link-limpar {
        text-decoration: underline;
        font-size: 12px;
        cursor: pointer;
        font-weight: 700;
        margin-right: 10px;
        display: inline-block;
        margin-bottom: 6px;
    }
    .text-soma {
        margin-bottom: 4px;
        margin-right: 10px;
        display: inline-block;
        background-color: #faf3dd;
        box-shadow: 0 1px 2px #ddd;
        padding: 6px 10px;
		}
    .label-mostrar-selecionados {
        vertical-align: super;
        font-size: 12px;
        font-weight: 550;
    }

</style>

<script>
    import Button from 'primevue/button'
    import Calendar from 'primevue/calendar'
    import Column from 'primevue/column'
    import DataTable from 'primevue/datatable'
    import DataView from 'primevue/dataview'
    import Dropdown from 'primevue/dropdown'
    import InputMask from 'primevue/inputmask'
    import InputText from 'primevue/inputtext'
    import Paginator from 'primevue/paginator'
    import MultiSelect from 'primevue/multiselect'
    import Checkbox from 'primevue/checkbox'
    import InputSwitch from 'primevue/inputswitch'
    import Panel from 'primevue/panel'
    import ProgressBar from 'primevue/progressbar'
    import Tooltip from 'primevue/tooltip'
    import wsConfigs from "@/middleware/configs"
    import { Caixas, DominioValor, Relatorio } from "@/middleware"
    import moment from "moment"
    import { pCalendarLocale_ptbr } from './../../utils'
    const _ = require('lodash')

    export default {
        components: { Button, Calendar, Column, DataTable, DataView, Dropdown,
            InputText, InputMask, Paginator, Panel, ProgressBar, MultiSelect, Checkbox, InputSwitch },
        directives: { tooltip: Tooltip },
        beforeRouteEnter (to, from, next) {
			next(vm => {
				if (vm.$store.state[vm.$route.name] )
                    vm.filters = vm.$store.state[vm.$route.name]
                vm.applyFilters()
				vm.$store.commit('clearFilters')

			})
		},
		beforeRouteLeave (to, from, next) {
            this.$store.state[from.name] = this.filters
			next()
		},
        created() {
            //this.applyFilters()
            DominioValor.findAll({ds_mnemonico: ['TIPO_SOLICITACAO']}).then(response => {
                this.options.estorno_tipos = [{ value: null, text: 'TODAS' }, ...response.data?.TIPO_SOLICITACAO.valores.map(tipo => ({ value: tipo.ie_valor, text: tipo.ds_valor }))]
            })
            Caixas.findAll({ie_tipo:'S'}).then(response => {
                this.options.caixas_supervisao = [{ value: null, text: 'TODAS' }, ...response.data.map(caixa => ({ value: caixa.id, text: caixa.nm_caixa }))]
            })
        },
        data() {
            return {
                waiting: false,
                windowInnerWidth: window.innerWidth,
                ptbr: pCalendarLocale_ptbr,
                list: [],
                selecionados: [],
                selecionadoIds: [],
                itensIds: [],
				mostrarSelecionados: false,
				somaTotal: 0,
                paginator: {
                    page: this.$route.query.pg || 1,
                    per_page: wsConfigs.paginator_perPage,
                    count: 0
                },
                paginadorDinamico: {
					rows: wsConfigs.paginator_perPage,
					first: 0,
					total: 0,
					page: this.$route.query.pg || 1,
					onPage: null
				},
                order: {
                    field: 'dt_criado',
                    sortOrder: -1
                },
                filters: {
                    cd_transacao: null,
                    cd_guia: null,
                    nr_cpf: ' ',
                    ie_situacao: null,
                    dt_inicial: null,
                    dt_final: null,
                    dt_pagamento_inicial: null,
                    dt_pagamento_final: null,
                    cd_forma_pagamento_list: null,
                },
                options: {
                    ie_situacao: [
                        { value: null, text: 'TODAS' },
                        { value: 'PEN', text: 'Pendente' },
                        { value: 'APR', text: 'Aprovado' },
                        { value: 'CAN', text: 'Cancelado' }
                    ],
                    formas_pagamento: [
                        { value: [2,5,6], text: 'Cartão' }, // 2: Cartão, 5: Débito, 6: Crédito
                        { value: [1], text: 'Dinheiro' },
                        { value: [4], text: 'Medclub Crédito (MEDCRED)' },
                        { value: [3], text: 'Pix / Transferência' }
                    ],
                    ie_tipo_operacao: [
                        { value: null, text: "TODAS" },
                        { value: "ATR", text: "Autorizado" },
                        { value: "NAT", text: "Não autorizado" },
                        { value: "PAT", text: "Pendente autorização" },
                    ],
                    estorno_tipos: [],
                    caixas_supervisao: []
                }
            }
        },
        watch: {
            'selecionadoIds': function(val) {
                if(this.selecionadoIds.length < this.selecionados.length) {
                    this.selecionados = _.filter(this.selecionados, s => this.selecionadoIds.includes(s.id))
                } else {
                    this.selecionados = _.uniqBy(_.union(this.selecionados, _.filter(this.list, l => this.selecionadoIds.includes(l.id))), 'id')
                }

				if (!val.length)
                this.mostrarSelecionados = false
                else if (this.mostrarSelecionados)
                this.paginadorDinamico['total'] = val.length
            },
			mostrarSelecionados: function() {
				this.atualizaPaginacao()
			},
		},
        computed: {
			selecionarTodos: {
				get() {
                    return this.mostrarSelecionados || this.selecionadoIds.length && _.every(this.itensIds, (v) => this.selecionadoIds.includes(v))
				},
				set(val) {
                    if(val) {
						this.selecionadoIds = _.union(this.selecionadoIds, this.itensIds)
                    } else {
						this.selecionadoIds = this.mostrarSelecionados ? [] : _.xor(this.selecionadoIds, this.itensIds)
                    }
				}
			},
			sum: function() {
				return _.sumBy(this.selecionados, 'nr_valor')
			},
			listaDinamica: function() {
				if (this.mostrarSelecionados) {
					const reagrupa = _.groupBy(this.selecionados, (v) => _.toInteger((_.indexOf(this.selecionados, v))/this.paginadorDinamico.rows))
					return reagrupa[this.paginadorDinamico.page] 
				} else
					return this.list
			},
		},
        methods: {
            statusTooltip (params) {
                switch (params) {
                    case 'ATR':
                        return 'Autorizado';
                    case 'NAT':
                        return 'Não autorizado';
                    case 'PAT':
                        return 'Pendente autorização';
                }
            },
            limparSelecao () {
				// this.selecionados = []
                this.selecionadoIds = []
			},
            verificaFiltrosImpressao () {
                return this.filters.dt_inicial || this.filters.dt_final
            },
            imprimirRelatorio () {
                let dataSend = {
                    cd_relatorio: 36,
                    nr_relatorio: 36,
                    cd_forma_pagamento_list: this.filters.cd_forma_pagamento_list,
                    ie_situacao_list: this.filters.ie_situacao,
                    ie_tipo_relatorio: 'pdf',
                    sintetico: false,
                    tipo_estorno: this.filters.ie_tipo,
                }

                if (this.filters.dt_inicial) dataSend.dt_inicial = moment(this.filters.dt_inicial).format('YYYY-MM-DD')
                if (this.filters.dt_final) dataSend.dt_final = moment(this.filters.dt_final).format('YYYY-MM-DD')

                Relatorio.createGenerico(dataSend).then(response => {
                    if ([400, 403].includes(response.status)) {
                        if (response.data.detail)
                            this.$toast.error(response.data.detail, { duration: 3000 })
                    }else if (response.status == 200) {
                        this.$toast.info('Relatório em processamento.', { duration: 4000 })
                        window.open(`/relatorio`, '_blank').focus;
                    } else if (response.status == 201) {
                        this.$toast.success('Relatório gerado com sucesso.', { duration: 4000 })
                        window.open(`/relatorio`, '_blank').focus;
                    }
                })
            },
            formatPrice (val) {
                return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(val)
            },
            onPage (ev) {
                this.paginadorDinamico.page = ev.page + 1 
                this.applyFilters(this.paginadorDinamico.page)
            },
            changeBackgroundColor(ev) {
                console.log(ev)
            },
            clearFilters () {
                this.paginatorDinamico.page = 1
                Object.keys(this.filters).forEach(k => { if (k !== 'cd_guia_tipo') this.filters[k] = null })
                this.applyFilters()
            },
            applyFilters (page) {
                const p = page || 1
                
				this.paginadorDinamico.page = p
				this.paginator.page = p

                let params = { paginacao: true, page: this.paginadorDinamico.page, per_page: this.paginadorDinamico.rows }
                params.order = `${ this.order.sortOrder == -1 ? '-' : '' }${ this.order.field }`

                if (this.$route.query.pg != p)
                    this.$router.replace( { query: { pg: p } } )
    
                Object.keys(this.filters).forEach((key) => {
                    if (this.filters[key] && _.trim(this.filters[key])) {
                        if(key == 'cd_forma_pagamento_list' && this.filters[key].length > 0) {
                            params[key] = this.filters[key].flat().join(',')
                        }

                        if (key == 'nr_cpf') {
						params[key] = this.filters[key].replace(/\D/g, '')
					}
                        else if (key.substr(0, 3) == 'dt_') params[key] = moment(this.filters[key]).format('YYYY-MM-DD')
                        else params[key] = this.filters[key]
                    }
                })

                this.getList(params)
            },
            getList (params) {
                this.waiting = true
                Caixas.findAllEstorno(params).then(response => {
                    this.waiting = false

                    if (response.status == 404 && this.paginator.page > 1) {
                        this.paginator.page = 1
                        this.applyFilters()
                    }

                    if (response.status == 200) {
                        this.paginator.count = response.data.count
                        this.paginadorDinamico.total = response.data.count
                        this.list = response.data.results
                        this.itensIds = []
                        this.list.forEach(estorno => {
                            estorno.dt_criado_f = estorno.dt_criado ? moment(estorno.dt_criado).format('DD/MM/YYYY HH:mm') : ""
                            estorno.dt_aprovado_f = estorno.dt_aprovado ? moment(estorno.dt_aprovado).format('DD/MM/YYYY HH:mm') : ""
                            this.itensIds.push(estorno.id)
                        })
                    }
                    this.atualizaPaginacao()
                })
            },

            atualizaPaginacao () {
                this.paginadorDinamico['page'] = this.mostrarSelecionados 
                                            ? 0 
                                            : this.paginator.page  || 1
                ;

                [	
                    this.paginadorDinamico['first'],
                    this.paginadorDinamico['total'],
                    this.paginadorDinamico['onPage'],
                ] = this.mostrarSelecionados ? [
                    0,
                    this.selecionados.length,
                    ({ page })  => {
                        this.paginadorDinamico.page = page;
                        this.paginadorDinamico.first = this.paginadorDinamico.page * this.paginadorDinamico.rows;
                    },
                ] : [
                    ((this.paginadorDinamico.page - 1) * this.paginadorDinamico.rows),
                    this.paginator.count,
                    this.onPage,
                ]
            }
        }
    }
</script>
